import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { CurrencyPipe } from './util/getCurrencyName.pipe';

import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { EventManager } from './services/event.service';
import { ScriptLoader } from './services/scriptLoader.service';
import { ResellerSharedLibsModule } from './shared-libs.module';
import { FilterPipe } from './util/filter.pipe';
import { LocalizedDatePipe}  from './util/localized-date.pipe';
import { UnifyNumbersPipe } from './util/toUnifyNumbers.pipe';
import { ReturnFilterObj } from './util/returnFilterObj.pipe';
import { HomainsNumbersPipe } from './util/convertToHomainsNumbers.pipe';
import { AdReasonPipe } from './util/getAdReasonName.pipe';
import { CompanyLeadSourcePipe } from './util/getCompanyLeadSourceName.pipe';
import { TelesalesLeadSourcePipe } from './util/getTelesalesLeadSourceName.pipe';
import { TransactionsService } from '../pages/company/transactions/transactionsService.service';
import { CallingCountriesPipe } from './util/getCallingCountry.pipe';
import { CountUpDirective } from './util/count-up/count-up.directive';

@NgModule({
  imports: [
    CommonModule,
    ResellerSharedLibsModule
  ],
  declarations: [
    LocalizedDatePipe,
    FindLanguageFromKeyPipe,
    FilterPipe,
    UnifyNumbersPipe,
    HomainsNumbersPipe,
    ReturnFilterObj,
    AdReasonPipe,
    CallingCountriesPipe,
    CompanyLeadSourcePipe,
    TelesalesLeadSourcePipe,
    CurrencyPipe,
    CountUpDirective
  ],
  providers: [ScriptLoader, EventManager, LocalizedDatePipe, ConfirmationService, TransactionsService],
  exports: [
    ResellerSharedLibsModule,
    LocalizedDatePipe,
    FindLanguageFromKeyPipe,
    FilterPipe,
    UnifyNumbersPipe,
    HomainsNumbersPipe,
    ReturnFilterObj,
    AdReasonPipe,
    CompanyLeadSourcePipe,
    TelesalesLeadSourcePipe,
    CallingCountriesPipe,
    CurrencyPipe,
    CountUpDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule
    };
  }
}

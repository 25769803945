/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsAddonConfiguration } from '../models/hmnz-ts-addon-configuration';
import { TsAddonConfigurationFilters } from '../models/ts-addon-configuration-filters';

@Injectable({ providedIn: 'root' })
export class TsAddonConfigurationResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsAddonConfigurations()` */
  static readonly GetTsAddonConfigurationsPath = '/api/resource/TSAddonConfiguration';

  /**
   * Get TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsAddonConfigurations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsAddonConfigurations$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsAddonConfiguration>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsAddonConfigurationResourceService.GetTsAddonConfigurationsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsAddonConfiguration>;
        }>;
      })
    );
  }

  /**
   * Get TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsAddonConfigurations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsAddonConfigurations(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsAddonConfiguration>;
}> {
    return this.getTsAddonConfigurations$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsAddonConfiguration>;
}>): {
'data': Array<HmnzTsAddonConfiguration>;
} => r.body)
    );
  }

  /** Path part for operation `createTsAddonConfiguration()` */
  static readonly CreateTsAddonConfigurationPath = '/api/resource/TSAddonConfiguration';

  /**
   * Create a new TSAddonConfiguration resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsAddonConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsAddonConfiguration$Response(
    params: {
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsAddonConfiguration;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsAddonConfigurationResourceService.CreateTsAddonConfigurationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsAddonConfiguration;
        }>;
      })
    );
  }

  /**
   * Create a new TSAddonConfiguration resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsAddonConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsAddonConfiguration(
    params: {
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsAddonConfiguration;
}> {
    return this.createTsAddonConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsAddonConfiguration;
}>): {
'data': HmnzTsAddonConfiguration;
} => r.body)
    );
  }

  /** Path part for operation `patchTsAddonConfiguration()` */
  static readonly PatchTsAddonConfigurationPath = '/api/resource/TSAddonConfiguration';

  /**
   * Patch TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsAddonConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsAddonConfiguration$Response(
    params: {
      filters?: TsAddonConfigurationFilters;
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsAddonConfiguration>> {
    const rb = new RequestBuilder(this.rootUrl, TsAddonConfigurationResourceService.PatchTsAddonConfigurationPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsAddonConfiguration>;
      })
    );
  }

  /**
   * Patch TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsAddonConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsAddonConfiguration(
    params: {
      filters?: TsAddonConfigurationFilters;
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<HmnzTsAddonConfiguration> {
    return this.patchTsAddonConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsAddonConfiguration>): HmnzTsAddonConfiguration => r.body)
    );
  }

  /** Path part for operation `updateTsAddonConfiguration()` */
  static readonly UpdateTsAddonConfigurationPath = '/api/resource/TSAddonConfiguration/{name}';

  /**
   * Update TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsAddonConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsAddonConfiguration$Response(
    params: {

    /**
     * The name of the TSAddonConfiguration to update
     */
      name: string;
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsAddonConfiguration>> {
    const rb = new RequestBuilder(this.rootUrl, TsAddonConfigurationResourceService.UpdateTsAddonConfigurationPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsAddonConfiguration>;
      })
    );
  }

  /**
   * Update TSAddonConfiguration resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsAddonConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsAddonConfiguration(
    params: {

    /**
     * The name of the TSAddonConfiguration to update
     */
      name: string;
      body: HmnzTsAddonConfiguration
    },
    context?: HttpContext
  ): Observable<HmnzTsAddonConfiguration> {
    return this.updateTsAddonConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsAddonConfiguration>): HmnzTsAddonConfiguration => r.body)
    );
  }

}

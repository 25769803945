/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class TscallsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `telesalesTscallsInitTelesales()` */
  static readonly TelesalesTscallsInitTelesalesPath = '/api/method/telesales.tscalls.init_telesales';

  /**
   * Invoke the telesales.tscalls.init_telesales method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsInitTelesales()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsInitTelesales$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsInitTelesalesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.init_telesales method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsInitTelesales$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsInitTelesales(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsInitTelesales$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsCallMyLead()` */
  static readonly TelesalesTscallsCallMyLeadPath = '/api/method/telesales.tscalls.call_my_lead';

  /**
   * Invoke the telesales.tscalls.call_my_lead method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsCallMyLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsCallMyLead$Response(
    params: {

    /**
     * Employment ID
     */
      employment_id: string;

    /**
     * lead
     */
      lead_name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsCallMyLeadPath, 'post');
    if (params) {
      rb.query('employment_id', params.employment_id, {});
      rb.query('lead_name', params.lead_name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.call_my_lead method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsCallMyLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsCallMyLead(
    params: {

    /**
     * Employment ID
     */
      employment_id: string;

    /**
     * lead
     */
      lead_name: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsCallMyLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsNextCall()` */
  static readonly TelesalesTscallsNextCallPath = '/api/method/telesales.tscalls.next_call';

  /**
   * Invoke the telesales.tscalls.next_call method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsNextCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsNextCall$Response(
    params: {

    /**
     * My Employemnt ID
     */
      employment: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsNextCallPath, 'post');
    if (params) {
      rb.query('employment', params.employment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.next_call method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsNextCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsNextCall(
    params: {

    /**
     * My Employemnt ID
     */
      employment: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsNextCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsDoneCall()` */
  static readonly TelesalesTscallsDoneCallPath = '/api/method/telesales.tscalls.done_call';

  /**
   * Invoke the telesales.tscalls.done_call method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsDoneCall()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsDoneCall$Response(
    params: {

    /**
     * Call ID
     */
      call: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsDoneCallPath, 'post');
    if (params) {
      rb.query('call', params.call, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.done_call method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsDoneCall$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsDoneCall(
    params: {

    /**
     * Call ID
     */
      call: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsDoneCall$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsRemainingCalls()` */
  static readonly TelesalesTscallsRemainingCallsPath = '/api/method/telesales.tscalls.remaining_calls';

  /**
   * Invoke the telesales.tscalls.remaining_calls method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsRemainingCalls()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsRemainingCalls$Response(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Employment ID
     */
      employment: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsRemainingCallsPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.query('employment', params.employment, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.remaining_calls method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsRemainingCalls$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsRemainingCalls(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Employment ID
     */
      employment: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsRemainingCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsStopWork()` */
  static readonly TelesalesTscallsStopWorkPath = '/api/method/telesales.tscalls.stop_work';

  /**
   * Invoke the telesales.tscalls.stop_work method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsStopWork()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsStopWork$Response(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsStopWorkPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.stop_work method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsStopWork$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsStopWork(
    params: {

    /**
     * Item Id
     */
      item: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsStopWork$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsEditSegments()` */
  static readonly TelesalesTscallsEditSegmentsPath = '/api/method/telesales.tscalls.edit_segments';

  /**
   * Invoke the telesales.tscalls.edit_segments method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsEditSegments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsEditSegments$Response(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Segments Comma Separated
     */
      segments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsEditSegmentsPath, 'post');
    if (params) {
      rb.query('item', params.item, {});
      rb.query('segments', params.segments, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.edit_segments method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsEditSegments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsEditSegments(
    params: {

    /**
     * Item Id
     */
      item: string;

    /**
     * Segments Comma Separated
     */
      segments: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsEditSegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesTscallsRefreshPToken()` */
  static readonly TelesalesTscallsRefreshPTokenPath = '/api/method/telesales.tscalls.refresh_p_token';

  /**
   * Invoke the telesales.tscalls.refresh_p_token method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesTscallsRefreshPToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsRefreshPToken$Response(
    params: {

    /**
     * Token
     */
      token: string;

    /**
     * Device Id
     */
      device_id: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TscallsService.TelesalesTscallsRefreshPTokenPath, 'post');
    if (params) {
      rb.query('token', params.token, {});
      rb.query('device_id', params.device_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.tscalls.refresh_p_token method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesTscallsRefreshPToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesTscallsRefreshPToken(
    params: {

    /**
     * Token
     */
      token: string;

    /**
     * Device Id
     */
      device_id: string;
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesTscallsRefreshPToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzItemRequest } from '../models/hmnz-item-request';
import { ItemRequestFilters } from '../models/item-request-filters';

@Injectable({ providedIn: 'root' })
export class ItemRequestResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getItemRequests()` */
  static readonly GetItemRequestsPath = '/api/resource/ItemRequest';

  /**
   * Get ItemRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemRequests$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzItemRequest>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ItemRequestResourceService.GetItemRequestsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzItemRequest>;
        }>;
      })
    );
  }

  /**
   * Get ItemRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemRequests(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzItemRequest>;
}> {
    return this.getItemRequests$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzItemRequest>;
}>): {
'data': Array<HmnzItemRequest>;
} => r.body)
    );
  }

  /** Path part for operation `createItemRequest()` */
  static readonly CreateItemRequestPath = '/api/resource/ItemRequest';

  /**
   * Create a new ItemRequest resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createItemRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createItemRequest$Response(
    params: {
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzItemRequest;
}>> {
    const rb = new RequestBuilder(this.rootUrl, ItemRequestResourceService.CreateItemRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzItemRequest;
        }>;
      })
    );
  }

  /**
   * Create a new ItemRequest resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createItemRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createItemRequest(
    params: {
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzItemRequest;
}> {
    return this.createItemRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzItemRequest;
}>): {
'data': HmnzItemRequest;
} => r.body)
    );
  }

  /** Path part for operation `patchItemRequest()` */
  static readonly PatchItemRequestPath = '/api/resource/ItemRequest';

  /**
   * Patch ItemRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchItemRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchItemRequest$Response(
    params: {
      filters?: ItemRequestFilters;
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzItemRequest>> {
    const rb = new RequestBuilder(this.rootUrl, ItemRequestResourceService.PatchItemRequestPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzItemRequest>;
      })
    );
  }

  /**
   * Patch ItemRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchItemRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchItemRequest(
    params: {
      filters?: ItemRequestFilters;
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<HmnzItemRequest> {
    return this.patchItemRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzItemRequest>): HmnzItemRequest => r.body)
    );
  }

  /** Path part for operation `updateItemRequest()` */
  static readonly UpdateItemRequestPath = '/api/resource/ItemRequest/{name}';

  /**
   * Update ItemRequest resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateItemRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateItemRequest$Response(
    params: {

    /**
     * The name of the ItemRequest to update
     */
      name: string;
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzItemRequest>> {
    const rb = new RequestBuilder(this.rootUrl, ItemRequestResourceService.UpdateItemRequestPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzItemRequest>;
      })
    );
  }

  /**
   * Update ItemRequest resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateItemRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateItemRequest(
    params: {

    /**
     * The name of the ItemRequest to update
     */
      name: string;
      body: HmnzItemRequest
    },
    context?: HttpContext
  ): Observable<HmnzItemRequest> {
    return this.updateItemRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzItemRequest>): HmnzItemRequest => r.body)
    );
  }

}

/// <reference types="@angular/localize" />

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import 'jquery';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as HomainsIOD from '@sentry/browser';

if (environment.production) {
    enableProdMode();
    HomainsIOD.init({
        dsn: 'https://f1ae2d9914884f7cbf6648743f690dfa@iod.homains.eu/7',
        autoSessionTracking: false,
        environment: 'production',
        release: '2.2.3'
    });
} else {
    HomainsIOD.init({
        dsn: 'https://f1ae2d9914884f7cbf6648743f690dfa@iod.homains.eu/7',
        autoSessionTracking: false,
        environment: 'development',
        allowUrls: ['https://alpha.hanbee3.com'],
        release: '2.2.4-SNAPSHOT'
    });
}

function bootstrap() {
    platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
};


if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}


import { AfterViewInit, Component, Injector, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "ngx-webstorage";
import { PrimeNGConfig } from "primeng/api";
import { filter, map } from "rxjs/operators";
import { ProjectName } from "./app.constants";
import { EventService } from "./market-place/core/services/event.service";
import { LanguageService } from "./market-place/core/services/language.service";
import { AccountService } from "./market-place/core/auth/services/account.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})

export class AppComponent implements OnInit, AfterViewInit {

  currentLang = "ar";
  title: any = {
    en: "",
    ar: "",
  };
  langService: LanguageService;
  eventService: EventService;
  accountService: AccountService;
  translateService: TranslateService;
  config: PrimeNGConfig;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private localStorageService: LocalStorageService,
    public injector: Injector
  ) {
    this.translateService = injector.get<TranslateService>(TranslateService);
    this.config = injector.get<PrimeNGConfig>(PrimeNGConfig);
    this.langService = injector.get<LanguageService>(LanguageService);
    this.eventService = injector.get<EventService>(EventService);
    this.accountService = injector.get<AccountService>(AccountService);
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // tslint:disable-next-line: no-string-literal
        // window.scrollTo(0, 0);
      }
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async ngAfterViewInit() {
    await this.sleep(1000);
  }

  ngOnInit(): void {
    // PrimeNG translation
    this.config.setTranslation({
      dayNames: ["حد", "اتنين", "تلات", "أربع", "خميس", "جمعه", "سبت"],
      dayNamesShort: ["حد", "اتنين", "تلات", "أربع", "خميس", "جمعه", "سبت"],
      dayNamesMin: ["حد", "اتنين", "تلات", "أربع", "خميس", "جمعه", "سبت"],
      monthNames: [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "اغسطس",
        "سبتمبر",
        "اوكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      monthNamesShort: [
        "يناير",
        "فبراير",
        "مارس",
        "إبريل",
        "مايو",
        "يونيو",
        "يوليو",
        "اغسطس",
        "سبتمبر",
        "اوكتوبر",
        "نوفمبر",
        "ديسمبر",
      ],
      today: "النهاردة",
      weekHeader: "Wk",
      startsWith: "ابدأ ب",
      contains: "يحتوى",
      notContains: "لا يحتوى",
      endsWith: "ينتهى ب",
      equals: "يساوى",
      notEquals: "لا يساوى",
      noFilter: "لا يوجد فلتر",
      lt: "Less than",
      lte: "Less than or equal to",
      gt: "Greater than",
      gte: "Greater than or equal to",
      is: "Is",
      isNot: "Is not",
      before: "Before",
      after: "After",
      dateIs: "Date is",
      dateIsNot: "Date is not",
      dateBefore: "Date is before",
      dateAfter: "Date is after",
      clear: "إمسح",
      apply: "خصص",
      matchAll: "تطابق الكل",
      matchAny: "تطابق أي ",
      addRule: "إضافة جديد",
      removeRule: "إمسح",
      accept: "Yes",
      reject: "No",
      choose: "Choose",
      upload: "Upload",
      cancel: "Cancel",
      dateFormat: "mm/dd/yy",
      weak: "Weak",
      medium: "Medium",
      strong: "Strong",
      passwordPrompt: "دخل الباسورد",
      emptyMessage: "مفيش داتا",
      emptyFilterMessage: "مفيش داتا"
    });

    this.localStorageService.retrieve("lang")
      ? this.langService.setLanguage(this.localStorageService.retrieve("lang"))
      : this.langService.setLanguage("ar");
    this.currentLang = this.langService.translate.currentLang;
    this.eventService.subscribe("lang", (lang) => {
      this.currentLang = lang.lang;
      this.setItNow();
    });
    this.setTitle();
  }

  setTitle(): void {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return appTitle;
        })
      )
      .subscribe((ttl: any) => {
        if (ttl.en) {
          this.title.en = " - " + ttl.en;
        } else {
          this.title.en = "";
        }
        if (ttl.ar) {
          this.title.ar = " - " + ttl.ar;
        } else {
          this.title.ar = "";
        }
        this.setItNow();
      });
  }

  setItNow(): void {
    if (this.currentLang === "ar") {
      this.titleService.setTitle(ProjectName.ar + this.title.ar);
    } else if (this.currentLang === "en") {
      this.titleService.setTitle(ProjectName.en + this.title.en);
    }
  }
}

if (typeof Worker !== "undefined") {
  // Create a new
  const worker = new Worker(new URL("./app.worker", import.meta.url));
  worker.onmessage = ({ data }) => {};
  worker.postMessage("hello");
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}

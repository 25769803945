import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, Subject, firstValueFrom} from 'rxjs';
import { SERVER_API_URL } from '../../../../app.constants';
import { Account } from '../../../../core/Backend/custom-backend/models/account';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';


@Injectable({ providedIn: 'root' })
export class AccountService {
  private userIdentity: any;
  private authenticated = false;
  authenticationState = new Subject<any>();

  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService
  ) {}

  fetch(): Observable<HttpResponse<any>> {
    return this.http.get<any>(SERVER_API_URL + 'api/method/homains_devops.auth.account', { observe: 'response' });
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(SERVER_API_URL + 'api/account', account, { observe: 'response' });
  }

  authenticate(identity): void {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  hasAnyAuthority(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.roles) {
      return false;
    } else {
      for (const authority of authorities) {
        if (this.userIdentity.roles.includes(authority)) {
          return true;
        }
      }
    }
    return false;
  }

  hasAuthority(authority: string): Promise<boolean> {
    if (!this.authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      id => {
        return Promise.resolve(id.roles && id.roles.includes(authority));
      },
      () => {
        return Promise.resolve(false);
      }
    );
  }

  identity(force?: boolean): Promise<Account> {
    if (force) {
      this.userIdentity = undefined;
    }
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    } else {
      if (this.getToken()) {
        return firstValueFrom(this.fetch())
          .then(response => {
            const account: any = response.body;
            if (account) {
              this.userIdentity = account.message;
              this.authenticated = true;
            } else {
              this.userIdentity = null;
              this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
          })
          .catch(err => {
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
          });
      } else {
        return null;
      }
    }
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  isIdentityResolved(): boolean {
    return this.userIdentity !== undefined;
  }

  getAuthenticationData(): Promise<any> {
    return firstValueFrom(this.authenticationState)
      .then(response => {
        return response;
      })
      .catch(() => {
        return null;
      });
  }

  getAuthenticationState(): Observable<any> {
    return this.authenticationState.asObservable();
  }

  getImageUrl(): string {
    return this.isIdentityResolved() ? this.userIdentity.imageUrl : null;
  }
  
  getToken(): string {
    return this.$localStorage.retrieve('authenticationtoken') || this.$sessionStorage.retrieve('authenticationtoken');
  }

}

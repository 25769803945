/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsContactNote } from '../models/hmnz-ts-contact-note';

@Injectable({ providedIn: 'root' })
export class TsContactNoteResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsContactNotes()` */
  static readonly GetTsContactNotesPath = '/api/resource/TSContactNote';

  /**
   * Get TSContactNote resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsContactNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsContactNotes$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsContactNote>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsContactNoteResourceService.GetTsContactNotesPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsContactNote>;
        }>;
      })
    );
  }

  /**
   * Get TSContactNote resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsContactNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsContactNotes(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsContactNote>;
}> {
    return this.getTsContactNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsContactNote>;
}>): {
'data': Array<HmnzTsContactNote>;
} => r.body)
    );
  }

  /** Path part for operation `createTsContactNote()` */
  static readonly CreateTsContactNotePath = '/api/resource/TSContactNote';

  /**
   * Create a new TSContactNote resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsContactNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsContactNote$Response(
    params: {
      body: HmnzTsContactNote
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsContactNote;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsContactNoteResourceService.CreateTsContactNotePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsContactNote;
        }>;
      })
    );
  }

  /**
   * Create a new TSContactNote resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsContactNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsContactNote(
    params: {
      body: HmnzTsContactNote
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsContactNote;
}> {
    return this.createTsContactNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsContactNote;
}>): {
'data': HmnzTsContactNote;
} => r.body)
    );
  }

}

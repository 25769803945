/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsOfflinePayment } from '../models/hmnz-ts-offline-payment';
import { TsOfflinePaymentFilters } from '../models/ts-offline-payment-filters';

@Injectable({ providedIn: 'root' })
export class TsOfflinePaymentResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsOfflinePayments()` */
  static readonly GetTsOfflinePaymentsPath = '/api/resource/TSOfflinePayment';

  /**
   * Get TSOfflinePayment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsOfflinePayments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsOfflinePayments$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsOfflinePayment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsOfflinePaymentResourceService.GetTsOfflinePaymentsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsOfflinePayment>;
        }>;
      })
    );
  }

  /**
   * Get TSOfflinePayment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsOfflinePayments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsOfflinePayments(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsOfflinePayment>;
}> {
    return this.getTsOfflinePayments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsOfflinePayment>;
}>): {
'data': Array<HmnzTsOfflinePayment>;
} => r.body)
    );
  }

  /** Path part for operation `createTsOfflinePayment()` */
  static readonly CreateTsOfflinePaymentPath = '/api/resource/TSOfflinePayment';

  /**
   * Create a new TSOfflinePayment resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsOfflinePayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsOfflinePayment$Response(
    params: {
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsOfflinePayment;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsOfflinePaymentResourceService.CreateTsOfflinePaymentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsOfflinePayment;
        }>;
      })
    );
  }

  /**
   * Create a new TSOfflinePayment resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsOfflinePayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsOfflinePayment(
    params: {
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsOfflinePayment;
}> {
    return this.createTsOfflinePayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsOfflinePayment;
}>): {
'data': HmnzTsOfflinePayment;
} => r.body)
    );
  }

  /** Path part for operation `patchTsOfflinePayment()` */
  static readonly PatchTsOfflinePaymentPath = '/api/resource/TSOfflinePayment';

  /**
   * Patch TSOfflinePayment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsOfflinePayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsOfflinePayment$Response(
    params: {
      filters?: TsOfflinePaymentFilters;
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsOfflinePayment>> {
    const rb = new RequestBuilder(this.rootUrl, TsOfflinePaymentResourceService.PatchTsOfflinePaymentPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsOfflinePayment>;
      })
    );
  }

  /**
   * Patch TSOfflinePayment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsOfflinePayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsOfflinePayment(
    params: {
      filters?: TsOfflinePaymentFilters;
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<HmnzTsOfflinePayment> {
    return this.patchTsOfflinePayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsOfflinePayment>): HmnzTsOfflinePayment => r.body)
    );
  }

  /** Path part for operation `updateTsOfflinePayment()` */
  static readonly UpdateTsOfflinePaymentPath = '/api/resource/TSOfflinePayment/{name}';

  /**
   * Update TSOfflinePayment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsOfflinePayment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsOfflinePayment$Response(
    params: {

    /**
     * The name of the TSOfflinePayment to update
     */
      name: string;
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsOfflinePayment>> {
    const rb = new RequestBuilder(this.rootUrl, TsOfflinePaymentResourceService.UpdateTsOfflinePaymentPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsOfflinePayment>;
      })
    );
  }

  /**
   * Update TSOfflinePayment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsOfflinePayment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsOfflinePayment(
    params: {

    /**
     * The name of the TSOfflinePayment to update
     */
      name: string;
      body: HmnzTsOfflinePayment
    },
    context?: HttpContext
  ): Observable<HmnzTsOfflinePayment> {
    return this.updateTsOfflinePayment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsOfflinePayment>): HmnzTsOfflinePayment => r.body)
    );
  }

}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class ResellerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `telesalesResellerMycode()` */
  static readonly TelesalesResellerMycodePath = '/api/method/telesales.reseller.mycode';

  /**
   * Invoke the telesales.reseller.mycode method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesResellerMycode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerMycode$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ResellerService.TelesalesResellerMycodePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.reseller.mycode method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesResellerMycode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerMycode(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesResellerMycode$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesResellerMynumbers()` */
  static readonly TelesalesResellerMynumbersPath = '/api/method/telesales.reseller.mynumbers';

  /**
   * Invoke the telesales.reseller.mynumbers method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesResellerMynumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerMynumbers$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ResellerService.TelesalesResellerMynumbersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.reseller.mynumbers method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesResellerMynumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerMynumbers(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesResellerMynumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `telesalesResellerCheckLogged()` */
  static readonly TelesalesResellerCheckLoggedPath = '/api/method/telesales.reseller.check_logged';

  /**
   * Invoke the telesales.reseller.check_logged method.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `telesalesResellerCheckLogged()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerCheckLogged$Response(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ResellerService.TelesalesResellerCheckLoggedPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Invoke the telesales.reseller.check_logged method.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `telesalesResellerCheckLogged$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  telesalesResellerCheckLogged(
    params?: {
      body?: any
    },
    context?: HttpContext
  ): Observable<any> {
    return this.telesalesResellerCheckLogged$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}

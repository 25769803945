/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTelesalesCompany } from '../models/hmnz-telesales-company';
import { TelesalesCompanyFilters } from '../models/telesales-company-filters';

@Injectable({ providedIn: 'root' })
export class TelesalesCompanyResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTelesalesCompanys()` */
  static readonly GetTelesalesCompanysPath = '/api/resource/TelesalesCompany';

  /**
   * Get TelesalesCompany resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTelesalesCompanys()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesCompanys$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTelesalesCompany>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCompanyResourceService.GetTelesalesCompanysPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTelesalesCompany>;
        }>;
      })
    );
  }

  /**
   * Get TelesalesCompany resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTelesalesCompanys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTelesalesCompanys(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTelesalesCompany>;
}> {
    return this.getTelesalesCompanys$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTelesalesCompany>;
}>): {
'data': Array<HmnzTelesalesCompany>;
} => r.body)
    );
  }

  /** Path part for operation `createTelesalesCompany()` */
  static readonly CreateTelesalesCompanyPath = '/api/resource/TelesalesCompany';

  /**
   * Create a new TelesalesCompany resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTelesalesCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesCompany$Response(
    params: {
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTelesalesCompany;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCompanyResourceService.CreateTelesalesCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTelesalesCompany;
        }>;
      })
    );
  }

  /**
   * Create a new TelesalesCompany resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTelesalesCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTelesalesCompany(
    params: {
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTelesalesCompany;
}> {
    return this.createTelesalesCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTelesalesCompany;
}>): {
'data': HmnzTelesalesCompany;
} => r.body)
    );
  }

  /** Path part for operation `patchTelesalesCompany()` */
  static readonly PatchTelesalesCompanyPath = '/api/resource/TelesalesCompany';

  /**
   * Patch TelesalesCompany resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTelesalesCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesCompany$Response(
    params: {
      filters?: TelesalesCompanyFilters;
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesCompany>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCompanyResourceService.PatchTelesalesCompanyPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesCompany>;
      })
    );
  }

  /**
   * Patch TelesalesCompany resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTelesalesCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTelesalesCompany(
    params: {
      filters?: TelesalesCompanyFilters;
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesCompany> {
    return this.patchTelesalesCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesCompany>): HmnzTelesalesCompany => r.body)
    );
  }

  /** Path part for operation `updateTelesalesCompany()` */
  static readonly UpdateTelesalesCompanyPath = '/api/resource/TelesalesCompany/{name}';

  /**
   * Update TelesalesCompany resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTelesalesCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesCompany$Response(
    params: {

    /**
     * The name of the TelesalesCompany to update
     */
      name: string;
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTelesalesCompany>> {
    const rb = new RequestBuilder(this.rootUrl, TelesalesCompanyResourceService.UpdateTelesalesCompanyPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTelesalesCompany>;
      })
    );
  }

  /**
   * Update TelesalesCompany resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTelesalesCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTelesalesCompany(
    params: {

    /**
     * The name of the TelesalesCompany to update
     */
      name: string;
      body: HmnzTelesalesCompany
    },
    context?: HttpContext
  ): Observable<HmnzTelesalesCompany> {
    return this.updateTelesalesCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTelesalesCompany>): HmnzTelesalesCompany => r.body)
    );
  }

}

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private translate: TranslateService,
    private toastr: ToastrService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if(err?.error?.message){
            if (err?.error?.message.message) {
              const message = err.error.message.message;
              this.translate.get(message).subscribe((translatedMessage: string) => {
                  const displayMessage = translatedMessage !== message ? translatedMessage : message;
                  this.toastr.error(displayMessage);
              });
            } else {
              const message = err.error.message;
              this.translate.get(message).subscribe((translatedMessage: string) => {
                  const displayMessage = translatedMessage !== message ? translatedMessage : message;
                  this.toastr.error(displayMessage);
              });
            }
          } else {
            switch(err.status) {
              case 417:
                const obj = JSON.parse(err.error._server_messages)[0];
                const messageObj = JSON.parse(obj);
                const message = messageObj.message;
                this.translate.get(message).subscribe((translatedMessage: string) => {
                  const displayMessage = translatedMessage !== message ? translatedMessage : message;
                  if (displayMessage.includes('is not valid')) {
                    this.toastr.warning(this.translate.instant('is not valid'));
                  } else {
                    this.toastr.warning(displayMessage);
                  }
                });
              break;
              case 500:
                if (!err.url.includes('telesales.public.stats')) {
                  this.toastr.error('فى مشكلة جرب وقت تانى');
                }
              break;
              case 503:
                this.toastr.error('فى مشكلة جرب وقت تانى');
              break;
            }
          }
        }
      })
    );
  }
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { HmnzTsCallNote } from '../models/hmnz-ts-call-note';
import { TsCallNoteFilters } from '../models/ts-call-note-filters';

@Injectable({ providedIn: 'root' })
export class TsCallNoteResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getTsCallNotes()` */
  static readonly GetTsCallNotesPath = '/api/resource/TSCallNote';

  /**
   * Get TSCallNote resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTsCallNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsCallNotes$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzTsCallNote>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallNoteResourceService.GetTsCallNotesPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzTsCallNote>;
        }>;
      })
    );
  }

  /**
   * Get TSCallNote resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTsCallNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTsCallNotes(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzTsCallNote>;
}> {
    return this.getTsCallNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzTsCallNote>;
}>): {
'data': Array<HmnzTsCallNote>;
} => r.body)
    );
  }

  /** Path part for operation `createTsCallNote()` */
  static readonly CreateTsCallNotePath = '/api/resource/TSCallNote';

  /**
   * Create a new TSCallNote resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTsCallNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallNote$Response(
    params: {
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzTsCallNote;
}>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallNoteResourceService.CreateTsCallNotePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzTsCallNote;
        }>;
      })
    );
  }

  /**
   * Create a new TSCallNote resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTsCallNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTsCallNote(
    params: {
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzTsCallNote;
}> {
    return this.createTsCallNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzTsCallNote;
}>): {
'data': HmnzTsCallNote;
} => r.body)
    );
  }

  /** Path part for operation `patchTsCallNote()` */
  static readonly PatchTsCallNotePath = '/api/resource/TSCallNote';

  /**
   * Patch TSCallNote resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTsCallNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallNote$Response(
    params: {
      filters?: TsCallNoteFilters;
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallNote>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallNoteResourceService.PatchTsCallNotePath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallNote>;
      })
    );
  }

  /**
   * Patch TSCallNote resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTsCallNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchTsCallNote(
    params: {
      filters?: TsCallNoteFilters;
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<HmnzTsCallNote> {
    return this.patchTsCallNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallNote>): HmnzTsCallNote => r.body)
    );
  }

  /** Path part for operation `updateTsCallNote()` */
  static readonly UpdateTsCallNotePath = '/api/resource/TSCallNote/{name}';

  /**
   * Update TSCallNote resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTsCallNote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallNote$Response(
    params: {

    /**
     * The name of the TSCallNote to update
     */
      name: string;
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzTsCallNote>> {
    const rb = new RequestBuilder(this.rootUrl, TsCallNoteResourceService.UpdateTsCallNotePath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzTsCallNote>;
      })
    );
  }

  /**
   * Update TSCallNote resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTsCallNote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTsCallNote(
    params: {

    /**
     * The name of the TSCallNote to update
     */
      name: string;
      body: HmnzTsCallNote
    },
    context?: HttpContext
  ): Observable<HmnzTsCallNote> {
    return this.updateTsCallNote$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzTsCallNote>): HmnzTsCallNote => r.body)
    );
  }

}

import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TelesalesCompanyResourceService } from '../../company-backend/services';
import { HmnzTelesalesCompany } from '../../company-backend/models';
import { Router } from '@angular/router';

interface ICompany {
  companyListCount: number; 
  companyData: HmnzTelesalesCompany
}

@Injectable({ providedIn: 'root' })
export class CompanyInfoService {

  companyInfo = new BehaviorSubject<ICompany>({companyData: null, companyListCount: null});

  constructor(
    private companyService: TelesalesCompanyResourceService,
    public router: Router
  ) {}

  setCompanyInfo(company): void {
    this.companyInfo.next({
      companyListCount: 1,
      companyData: company
    });
  }

  getCompanyInfo(): Observable<ICompany> {
    if (!this.companyInfo.value?.companyListCount) {
      this.refreshInfoData();
      return this.companyInfo.asObservable();
    } else {
      return this.companyInfo.asObservable();
    }
  }

  refreshInfoData() {
    this.companyService.getTelesalesCompanys$Response({
      fields: '["company_name", "name", "egp_wallet"]',
      filters: null,
      or_filters: null
    }).subscribe({
      next: (res) => {
        this.companyInfo.next({
          companyListCount: res.body.data.length,
          companyData: res.body.data[0]
        });
        if (res.body.data.length === 0 || !res.body.data[0].company_name) {
          this.router.navigate(['/profile/company-info']);
        }
      }
    });
  }

  clearCompanyInfo() {
    this.companyInfo.next(null);
  }

}

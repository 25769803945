/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CompanySegmentFilters } from '../models/company-segment-filters';
import { HmnzCompanySegment } from '../models/hmnz-company-segment';

@Injectable({ providedIn: 'root' })
export class CompanySegmentResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanySegments()` */
  static readonly GetCompanySegmentsPath = '/api/resource/CompanySegment';

  /**
   * Get CompanySegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanySegments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySegments$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzCompanySegment>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanySegmentResourceService.GetCompanySegmentsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzCompanySegment>;
        }>;
      })
    );
  }

  /**
   * Get CompanySegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanySegments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanySegments(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzCompanySegment>;
}> {
    return this.getCompanySegments$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzCompanySegment>;
}>): {
'data': Array<HmnzCompanySegment>;
} => r.body)
    );
  }

  /** Path part for operation `createCompanySegment()` */
  static readonly CreateCompanySegmentPath = '/api/resource/CompanySegment';

  /**
   * Create a new CompanySegment resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanySegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanySegment$Response(
    params: {
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzCompanySegment;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanySegmentResourceService.CreateCompanySegmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzCompanySegment;
        }>;
      })
    );
  }

  /**
   * Create a new CompanySegment resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanySegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanySegment(
    params: {
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzCompanySegment;
}> {
    return this.createCompanySegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzCompanySegment;
}>): {
'data': HmnzCompanySegment;
} => r.body)
    );
  }

  /** Path part for operation `patchCompanySegment()` */
  static readonly PatchCompanySegmentPath = '/api/resource/CompanySegment';

  /**
   * Patch CompanySegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanySegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanySegment$Response(
    params: {
      filters?: CompanySegmentFilters;
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzCompanySegment>> {
    const rb = new RequestBuilder(this.rootUrl, CompanySegmentResourceService.PatchCompanySegmentPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzCompanySegment>;
      })
    );
  }

  /**
   * Patch CompanySegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanySegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanySegment(
    params: {
      filters?: CompanySegmentFilters;
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<HmnzCompanySegment> {
    return this.patchCompanySegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzCompanySegment>): HmnzCompanySegment => r.body)
    );
  }

  /** Path part for operation `updateCompanySegment()` */
  static readonly UpdateCompanySegmentPath = '/api/resource/CompanySegment/{name}';

  /**
   * Update CompanySegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanySegment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanySegment$Response(
    params: {

    /**
     * The name of the CompanySegment to update
     */
      name: string;
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzCompanySegment>> {
    const rb = new RequestBuilder(this.rootUrl, CompanySegmentResourceService.UpdateCompanySegmentPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzCompanySegment>;
      })
    );
  }

  /**
   * Update CompanySegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanySegment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanySegment(
    params: {

    /**
     * The name of the CompanySegment to update
     */
      name: string;
      body: HmnzCompanySegment
    },
    context?: HttpContext
  ): Observable<HmnzCompanySegment> {
    return this.updateCompanySegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzCompanySegment>): HmnzCompanySegment => r.body)
    );
  }

  /** Path part for operation `deleteCompanySegment()` */
  static readonly DeleteCompanySegmentPath = '/api/resource/CompanySegment/{name}';

  /**
   * Delete CompanySegment resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanySegment()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanySegment$Response(
    params: {

    /**
     * The name of the CompanySegment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanySegmentResourceService.DeleteCompanySegmentPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete CompanySegment resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanySegment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanySegment(
    params: {

    /**
     * The name of the CompanySegment to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteCompanySegment$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}

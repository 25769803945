import {Injectable} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HmnzCompanyPaymentMethod, HmnzWalletTransaction } from '../../../../core/Backend/company-backend/models';
import { CompanyPaymentMethodResourceService, HelpersService, WalletTransactionResourceService } from '../../../../core/Backend/company-backend/services';

@Injectable({ providedIn: 'root' })
export class TransactionsService {

    public cards = new BehaviorSubject<HmnzCompanyPaymentMethod[]>(null);
    public transactionsCount = new BehaviorSubject<number>(0);
    public transactions = new BehaviorSubject<HmnzWalletTransaction[]>(null);

    constructor(
        private helpersService: HelpersService,
        private companyPaymentMethodService: CompanyPaymentMethodResourceService,
        private walletTransactionService: WalletTransactionResourceService
    ) {}

    loadCardsData() {
        this.companyPaymentMethodService.getCompanyPaymentMethods$Response({
        fields: '["name", "exp_month", "exp_year", "last4", "stripe_payment_method", "type"]'
        }).subscribe({
            next: (res) => {
                this.cards.next(res.body.data);
            }
        });
    }

    getCards(): Observable<HmnzCompanyPaymentMethod[]> {
      return this.cards.asObservable();
    }

    loadTransactionsCount(
        filters: string,
        orFilters: string)  {
        this.helpersService.homainsDevopsHelpersCount$Response({
            doctype: 'WalletTransaction',
            filters: filters,
            or_filters: orFilters,
        }).subscribe({
            next: (res) => {
                this.transactionsCount.next(res.body.message);
            }
        });
    }

    getTransactionsCount(): Observable<number> {
      return this.transactionsCount.asObservable();
    }

    loadTransactions(
        filters: string,
        orFilters: string,
        page: number,
        pageSize: number)  {
        this.walletTransactionService.getWalletTransactions$Response({
          fields: '["name", "client_secret", "invoice_link", "amount", "currency", "paid", "receipt", "creation"]',
          filters: filters,
          or_filters: orFilters,
          limit_page_length: pageSize,
          limit_start: page
        }).subscribe({
          next: (res) => {
            this.transactions.next(res.body.data);
          }
        });
    }

    getTransactions(): Observable<HmnzWalletTransaction[]> {
      return this.transactions.asObservable();
    }

    clearCardsData() {
      this.cards.next(null);
    }

    clearTransactions() {
      this.transactions.next(null);
    }

    clearTransactionsCount() {
      this.transactionsCount.next(null);
    }
}

/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CompanyLeadFilters } from '../models/company-lead-filters';
import { HmnzCompanyLead } from '../models/hmnz-company-lead';

@Injectable({ providedIn: 'root' })
export class CompanyLeadResourceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCompanyLeads()` */
  static readonly GetCompanyLeadsPath = '/api/resource/CompanyLead';

  /**
   * Get CompanyLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyLeads()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyLeads$Response(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': Array<HmnzCompanyLead>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyLeadResourceService.GetCompanyLeadsPath, 'get');
    if (params) {
      rb.query('filters', params.filters, {});
      rb.query('limit_page_length', params.limit_page_length, {});
      rb.query('limit_start', params.limit_start, {});
      rb.query('order_by', params.order_by, {});
      rb.query('or_filters', params.or_filters, {});
      rb.query('fields', params.fields, {"style":"form"});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': Array<HmnzCompanyLead>;
        }>;
      })
    );
  }

  /**
   * Get CompanyLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyLeads$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyLeads(
    params?: {
      filters?: string;
      limit_page_length?: number;
      limit_start?: number;
      order_by?: string;
      or_filters?: string;
      fields?: string;
    },
    context?: HttpContext
  ): Observable<{
'data': Array<HmnzCompanyLead>;
}> {
    return this.getCompanyLeads$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': Array<HmnzCompanyLead>;
}>): {
'data': Array<HmnzCompanyLead>;
} => r.body)
    );
  }

  /** Path part for operation `createCompanyLead()` */
  static readonly CreateCompanyLeadPath = '/api/resource/CompanyLead';

  /**
   * Create a new CompanyLead resource.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCompanyLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyLead$Response(
    params: {
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'data': HmnzCompanyLead;
}>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyLeadResourceService.CreateCompanyLeadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'data': HmnzCompanyLead;
        }>;
      })
    );
  }

  /**
   * Create a new CompanyLead resource.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCompanyLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCompanyLead(
    params: {
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<{
'data': HmnzCompanyLead;
}> {
    return this.createCompanyLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'data': HmnzCompanyLead;
}>): {
'data': HmnzCompanyLead;
} => r.body)
    );
  }

  /** Path part for operation `patchCompanyLead()` */
  static readonly PatchCompanyLeadPath = '/api/resource/CompanyLead';

  /**
   * Patch CompanyLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompanyLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyLead$Response(
    params: {
      filters?: CompanyLeadFilters;
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzCompanyLead>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyLeadResourceService.PatchCompanyLeadPath, 'patch');
    if (params) {
      rb.query('filters', params.filters, {"style":"form"});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzCompanyLead>;
      })
    );
  }

  /**
   * Patch CompanyLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompanyLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCompanyLead(
    params: {
      filters?: CompanyLeadFilters;
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<HmnzCompanyLead> {
    return this.patchCompanyLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzCompanyLead>): HmnzCompanyLead => r.body)
    );
  }

  /** Path part for operation `updateCompanyLead()` */
  static readonly UpdateCompanyLeadPath = '/api/resource/CompanyLead/{name}';

  /**
   * Update CompanyLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyLead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLead$Response(
    params: {

    /**
     * The name of the CompanyLead to update
     */
      name: string;
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HmnzCompanyLead>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyLeadResourceService.UpdateCompanyLeadPath, 'put');
    if (params) {
      rb.path('name', params.name, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HmnzCompanyLead>;
      })
    );
  }

  /**
   * Update CompanyLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyLead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLead(
    params: {

    /**
     * The name of the CompanyLead to update
     */
      name: string;
      body: HmnzCompanyLead
    },
    context?: HttpContext
  ): Observable<HmnzCompanyLead> {
    return this.updateCompanyLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<HmnzCompanyLead>): HmnzCompanyLead => r.body)
    );
  }

  /** Path part for operation `deleteCompanyLead()` */
  static readonly DeleteCompanyLeadPath = '/api/resource/CompanyLead/{name}';

  /**
   * Delete CompanyLead resources.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyLead()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLead$Response(
    params: {

    /**
     * The name of the CompanyLead to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CompanyLeadResourceService.DeleteCompanyLeadPath, 'delete');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Delete CompanyLead resources.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyLead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLead(
    params: {

    /**
     * The name of the CompanyLead to delete
     */
      name: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.deleteCompanyLead$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
